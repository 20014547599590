export default function(callback) {
  let wait

  return function(...args) {
    if (wait) return
    wait = true

    requestAnimationFrame(() => {
      wait = false
      callback(...args)
    })
  }
}
