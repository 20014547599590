import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import StoryTeaser from "src/components/StoryTeaser"
import Ad from "src/components/Ad"

export default ({ items, adType, size }) => {
  return (
    <Wrapper className={`-layout-slideshow`}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <StoryTeaser data={item} layout="slideshow" key={index} size={size} />
          {adType && index === 1 && <StyledAd type={adType} />}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`

const StyledAd = styled(Ad)`
  min-width: 300px;
  margin-right: 10px;

  ${media.desktopMedium} {
    min-width: calc((100% - 70px) / 8 * 2 + 10px);
  }
`
