const SIZES = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1320,
}

export const getSizes = sizes => {
  const mapToString = []

  Object.keys(SIZES).map(key => {
    if (sizes[key]) {
      mapToString.push(`(max-width: ${SIZES[key]}px) ${sizes[key]}`)
    }
  })

  mapToString.push(sizes.default)

  return mapToString.join(", ")
}
