import React, { useState, useRef, useCallback, useMemo } from "react"
import styled, { css } from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import Container from "src/components/Container"
import Icon from "src/components/Icon"
import SectionSlider from "src/components/SectionSlider"
import { navigate } from "gatsby"
import { articleNormalizer } from "src/utils/contentNormalizers"
import { useEffect } from "react"
import NoResultsInfo from "src/components/NoResultsInfo"
import routesConfig from "src/routes.config"
import { connectSearchBox } from "react-instantsearch-dom"
import { setSearchResults } from "src/store/search/actions"
import { useDispatch } from "react-redux"

const SearchInput = connectSearchBox(({ refine, currentRefinement }) => (
  <Input onChange={e => refine(e.target.value)} value={currentRefinement} inputMode="search" placeholder="Start typing"/>
))

let prevQuery

export default ({
  onSearchToggle,
  searchResults,
  searchState,
  isSearchStalled: isLoading,
}) => {
  const dispatch = useDispatch()
  const [formVisible, setFormVisible] = useState(false)
  const formVisibleRef = useRef(formVisible)
  formVisibleRef.current = formVisible
  const formRef = useRef()
  const [isFirstInput, setIsFirstInput] = useState(true)
  const [tipText, setTipText] = useState("")
  const parsedSearchResults = useMemo(() => {
    if (!searchResults) return null

    return {
      ...searchResults,
      hits: (searchResults.hits || []).map(articleNormalizer),
    }
  }, [searchResults])

  function focusInput() {
    if (!formRef.current) return
    const input = formRef.current.querySelector("input")
    if (!input) return
    input.focus()
  }

  function toggleForm(visible) {
    setFormVisible(visible)
    onSearchToggle(visible)
    document.body.classList[visible ? "add" : "remove"]("-search-open")
  }

  useEffect(() => {
    if (formVisible) {
      focusInput()
    }
  }, [formVisible])

  function handleKeyup(e) {
    if (formVisibleRef.current && e.code === "Escape") {
      toggleForm(false)
      e.preventDefault()
    }
  }

  const handleSubmit = useCallback(e => {
    e.preventDefault()
    navigate(routesConfig.search())
    toggleForm(false)
  }, [])

  useEffect(() => {
    if (!(searchState.query && searchState.query.length)) {
      setIsFirstInput(true)
    } else {
      setIsFirstInput(false)
    }
  }, [searchState.query])

  useEffect(() => {
    document.addEventListener("keyup", handleKeyup)
    return () => {
      document.removeEventListener("keyup", handleKeyup)
    }
  }, [])

  useEffect(() => {
    setTipText(
      searchState.query && searchState.query.length
        ? searchResults && searchResults.nbHits > 0
          ? `Hit ”${
              typeof navigator !== "undefined" &&
              navigator.userAgent.includes("Mac")
                ? "Return"
                : "Enter"
            }” to show all results`
          : "Nothing found"
        : "Start typing"
    )
  })

  useEffect(() => {
    const newQuery = searchState.query
    if (prevQuery !== newQuery && parsedSearchResults.query === newQuery) {
      dispatch(
        setSearchResults({
          searchResults: newQuery ? parsedSearchResults : null,
        })
      )
      prevQuery = newQuery
    }
  }, [searchState.query, parsedSearchResults])

  const path = typeof window !== "undefined" ? window.location.pathname : ''
  useEffect(() => {
    document.body.classList.remove("-search-open")
  }, [path])

  return (
    <Wrapper className={formVisible ? "-form-visible" : ""}>
      <Form onSubmit={handleSubmit} onClick={focusInput} ref={formRef}>
        <Container>
          <SearchInput
            type="text"
            placeholder="Search"
            value={searchState.query ? searchState.query : ""}
          />
          <Tip>{tipText}</Tip>
        </Container>
      </Form>

      {!(searchState.query && searchState.query.length) &&
      isFirstInput ? null : parsedSearchResults &&
        parsedSearchResults.hits.length ? (
        <Results>
          <SectionSlider
            source="search"
            title="Quick results"
            items={parsedSearchResults.hits}
          />
        </Results>
      ) : (
        !isFirstInput && (
          <Results scCenter={true}>
            <NoResultsInfo />
          </Results>
        )
      )}

      <Overlay
        onClick={() => {
          toggleForm(false)
        }}
      />
      <Switcher
        onClick={() => {
          toggleForm(!formVisible)
        }}
      >
        {!formVisible && <SwitcherIcon name="loupe" />}
        {formVisible && <SwitcherIcon name="x" />}
      </Switcher>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .-nav-visible & {
    visibility: hidden;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* visibility: hidden; */
  width: 100%;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translateY(-250px);
  background: #fff;
  /* transition: transform 0.25s ${p => p.theme.easing.easeOutQuart}; */
  pointer-events: none;
  &::before {
    /* content: ""; */
    width: 100%;
    height: calc(100vh - 250px);
    top: 100%;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    justify-content: flex-start;
    height: 70px;
    transform: translateY(-70px);
    &::before {
      height: calc(100vh - 70px);
    }
  }

  ${Container} {
    display: flex;
    align-items: center;
    @media (max-width: ${breakpoints.tablet - 1}px) {
      padding-right:60px;
    }
  }

  ${Wrapper}.-form-visible & {
    /* visibility: visible; */
    transform: none;
    pointer-events: all;
  }
`

const Input = styled.input`
  flex: 1;
  padding: 27px 0;
  font-weight: 600;
  font-size: 20px;


  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 20px 0;
  }
  @media (min-width: ${breakpoints.tablet}px) {
    ::placeholder { 
      opacity:0;
    }
  }
`

const Tip = styled.p`
  color: rgba(0, 0, 0, 0.3);
  @media (max-width: ${breakpoints.tablet - 1}px) {
    display: none;
  }
`

const Results = styled.div`
  width: 100%;
  padding-top: 50px;
  position: fixed;
  top: 250px;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: ${p => p.theme.color.backgroundSearch};
  opacity: 0;
  pointer-events: none;
  transform: translateY(-250px);
  /* transition: transform 0.25s ${p => p.theme.easing.easeOutQuart}; */

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding-top: 20px;
    top: 70px;
    transform: translateY(-70px);
  }

  ${p =>
    p.scCenter &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${Wrapper}.-form-visible & {
    opacity: 1;
    pointer-events: auto;
    transform: none;
  }
`

const Overlay = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  transition: 0.25s ${p => p.theme.easing.easeOutQuart};

  ${Wrapper}.-form-visible & {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
`

const Switcher = styled.button`
  width: 60px;
  height: 70px;
  padding: 0;
  position: relative;
  z-index: 1;

  ${media.tablet} {
    width: 70px;
    margin-right: -5px;
  }
`

const SwitcherIcon = styled(Icon)`
  width: 20px;
  height: 20px;

  &[data-name="x"] {
    width: 18px;
    height: 18px;
  }
`
