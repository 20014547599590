import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import Icon from "src/components/Icon"
import SocialMediaIcons from "src/components/SocialMediaIcons"
import NewsletterForm from "src/components/NewsletterForm"
import { PrimaryTitle } from "src/components/Typography"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"

export default ({ onNavToggle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isMenuHover, setIsMenuHover] = useState(false)
  const [isSubmenuHover, setIsSubmenuHover] = useState(false)

  const {
    wordpressAirSettings,
    wordpressAcfOptions: {
      options: { site_menu: menuOptions },
    },
  } = useStaticQuery(graphql`
    query HeaderNavigationQuery {
      wordpressAirSettings {
        header {
          title
          path
          items {
            title
            path
          }
        }
      }
      wordpressAcfOptions {
        options {
          site_menu {
            image {
              source_url
              alt_text
            }
          }
        }
      }
    }
  `)

  function toggleMenu() {
    setIsOpen(!isOpen)
    onNavToggle(!isOpen)
    document.body.classList.toggle("-no-scroll")
  }

  return (
    <Wrapper
      className={
        (isOpen ? "-open" : "") +
        (isMenuHover ? " -menu-hover" : "") +
        (isSubmenuHover ? " -submenu-hover" : "")
      }
    >
      <Switcher
        onClick={() => {
          toggleMenu()
        }}
      >
        {[...Array(3).keys()].map(index => (
          <SwitcherLine key={index} />
        ))}
        <SwitcherIcon name="x" />
      </Switcher>
      <Menu>
        <MenuTitle>
          <Link
            to="/"
            onClick={() => {
              toggleMenu(false)
            }}
          >
            ArtReview
          </Link>
        </MenuTitle>
        <MenuList>
          {wordpressAirSettings.header.map(({ title, path, items }, index) => (
            <MenuListItem
              onMouseEnter={() => {
                setIsMenuHover(true)
              }}
              onMouseLeave={() => {
                setIsMenuHover(false)
              }}
              key={index}
            >
              <MenuListLink
                to={path}
                onClick={() => {
                  toggleMenu(false)
                }}
              >
                {title}
              </MenuListLink>
              {items && (
                <MenuSubnav>
                  {items.map(({ title, path }, index) => (
                    <MenuSubnavItem
                      onMouseEnter={() => {
                        setIsSubmenuHover(true)
                      }}
                      onMouseLeave={() => {
                        setIsSubmenuHover(false)
                      }}
                      key={index}
                    >
                      <MenuSubnavLink
                        to={path}
                        onClick={() => {
                          toggleMenu(false)
                        }}
                      >
                        {title}
                      </MenuSubnavLink>
                    </MenuSubnavItem>
                  ))}
                </MenuSubnav>
              )}
            </MenuListItem>
          ))}
        </MenuList>
        {menuOptions && menuOptions.image && (
          <Link to="/subscribe">
            <Issue
              src={menuOptions.image.source_url}
              alt={menuOptions.image.alt_text}
              loading="lazy"
            />
          </Link>
        )}
        <Newsletter>
          <NewsletterTitle>ArtReview newsletters</NewsletterTitle>
          <StyledNewsletterForm />
        </Newsletter>
        <SocialMedia />
      </Menu>
      <Overlay
        onClick={() => {
          toggleMenu()
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  &.-open {
    color: #000;
  }
`

const Switcher = styled.button`
  width: 70px;
  height: 70px;
  padding: 0;
  position: relative;
  z-index: 5;

  ${Wrapper}.-open & {
    position: fixed;
    top: 2px;
  }

  .-search-open & {
    visibility: hidden;
  }

  ${media.tablet} {
    ${Wrapper}.-open & {
      top: 4px;
    }
  }
`

const SwitcherLine = styled.span`
  display: block;
  width: 30px;
  height: 2px;
  margin: 4px auto;
  background: currentColor;

  ${Wrapper}.-open & {
    opacity: 0;
  }
`

const SwitcherIcon = styled(Icon)`
  opacity: 0;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 26px;
  left: 26px;

  ${Wrapper}.-open & {
    opacity: 1;
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transform: translateX(-100%);
  background: #fff;
  color: #000;
  transition: 0.25s ${p => p.theme.easing.easeOutQuart};
  transition-property: width, transform;

  ${Wrapper}.-open & {
    transform: none;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    text-align: center;
    padding: 22px 50px 20px;
  }

  ${media.tablet} {
    width: 385px;
    padding: 22px 50px 45px 85px;

    ${Wrapper}.-menu-hover & {
      width: 540px;
    }
  }
`

const MenuTitle = styled(PrimaryTitle)`
  margin-bottom: 60px;
  font-size: 28px;

  @media (max-height: 480px) {
    margin-bottom: 30px;
  }
`

const MenuList = styled.ul`
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;

  ${media.tablet} {
    flex: 1;
    max-width: 110px;

    ${Wrapper}.-menu-hover & {
      max-width: 120px;
    }
  }
`

const MenuListItem = styled.li`
  position: relative;

  ${media.tablet} {
    ${Wrapper}.-menu-hover &:not(:hover) {
      color: rgba(0, 0, 0, 0.2);
    }
  }
`

const MenuListLink = styled(Link)`
  display: block;
  transition: 0.1s;
`

const MenuSubnav = styled.ul`
  @media (max-width: ${breakpoints.tablet - 1}px) {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    &:not(:empty) {
      margin-bottom: 15px;
    }
  }

  ${media.tablet} {
    visibility: hidden;
    width: 305px;
    position: absolute;
    top: 0;
    left: 120px;

    ${MenuListItem}:hover & {
      visibility: visible;
    }
  }
`

const MenuSubnavItem = styled.li`
  @media (max-width: ${breakpoints.tablet - 1}px) {
    display: inline-block;
    margin: 0 5px;
  }

  ${media.tablet} {
    ${Wrapper}.-submenu-hover &:not(:hover) {
      color: rgba(0, 0, 0, 0.2);
    }
  }
`

const MenuSubnavLink = styled(Link)`
  ${media.tablet} {
    display: block;
    padding-left: 90px;
    transition: 0.1s;
  }
`

const Issue = styled.img`
  display: none;
  height: 260px;
  margin-bottom: 50px;
  margin-left: -1px;

  @media (max-height: 730px) {
    height: 200px;
  }

  ${media.tablet} {
    @media (min-height: 670px) {
      display: block;
    }
  }
`

const Newsletter = styled.div`
  display: none;

  ${media.tablet} {
    @media (min-height: 870px) {
      display: block;
    }
  }
`

const NewsletterTitle = styled.h3`
  font-weight: 600;
  font-size: 17px;
  height: 20px;
`

const StyledNewsletterForm = styled(NewsletterForm)`
  input[type="email"] {
    padding-bottom: 14px;
  }
`

const SocialMedia = styled(SocialMediaIcons)`
  justify-content: center;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    @media (max-height: 580px) {
      display: none;
    }
  }

  ${media.tablet} {
    justify-content: flex-start;
  }
`

const Overlay = styled.div`
  display: none;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  transition: 0.25s ${p => p.theme.easing.easeOutQuart};

  ${Wrapper}.-open & {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }

  ${media.tablet} {
    display: block;
  }
`
