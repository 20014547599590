import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"

const PrimaryTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 1;

  ${media.tablet} {
    font-size: 30px;
  }
`

const SecondaryTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;

  p + &,
  ul + &,
  ol + & {
    margin-top: 50px;
  }

  h2 + & {
    margin-top: 20px;
  }

  ${media.tablet} {
    p + &,
    ul + &,
    ol + & {
      margin-top: 65px;
    }
  }
`

const Paragraph = styled.p`
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 25px;
  font-family: ${p => p.theme.font.secondary};

  b,
  strong {
    font-weight: 600;
    font-family: ${p => p.theme.font.primary};
  }

  &.has-text-align-center{
    text-align:center;
  }
  &.has-text-align-right{
    text-align:right;
  }
  &.has-text-align-left{
    text-align:left;
  }
  &.has-text-align-justify{
    text-align:justify;
  }
  &.rtl{
    direction:rtl;
  }

  a {
    text-decoration: underline;
    color: ${p => p.theme.color.grey};
  }

  ul + & {
    margin-top: 54px;
  }
`

const ListWrapper = styled.ul`
  max-width: 455px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 20px;
  font-family: ${p => p.theme.font.secondary};

  p + & {
    margin-top: 54px;
  }
`

const ListItem = styled.li`
  margin-bottom: 20px;
  position: relative;
  padding-left: 50px;

  &::before {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    top: 8px;
    left: 22px;
    background: currentColor;
    border-radius: 50%;
  }
`

const List = ({ items }) => (
  <ListWrapper>
    {items.map((item, index) => (
      <ListItem key={index}>{item}</ListItem>
    ))}
  </ListWrapper>
)

const Verse = styled.div`
  margin-bottom: 39px;
  font-size: 14px;
  line-height: 20px;

  strong {
    display: block;
    margin-bottom: 11px;
    font-size: 17px;

    + br {
      display: none;
    }
  }

  a {
    color: rgba(0, 0, 0, 0.5);
  }

  &:first-of-type {
    margin-top: 75px;
  }

  + ${SecondaryTitle} {
    margin-top: 89px;
  }
`

export { PrimaryTitle, SecondaryTitle, Paragraph, List, Verse }
