import React from "react"
import { graphql } from "gatsby"
import Figure from "src/components/Figure"
import { get } from "lodash"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

// export const query = graphql`

// `

export default ({ media, size = "full", type = "simple", ...props }) => {
  const determineImageURL = (media, size) => {
    let url = media.source_url || null
    if (size !== "full") {
      const sized_url = get(
        media,
        `media_details.sizes.${size}.source_url`,
        null
      )
      if (sized_url) {
        url = sized_url
      }
    }

    return url
  }

  props.imageSrc = determineImageURL(media, size)

  if (media.caption) {
    props.caption = media.caption
  }

  if (media.alt_text) {
    props.imageAlt = media.alt_text
  }
  
  const { width, height, sizes = {} } = (media && media.media_details) || {}

  let srcSet = []
  Object.values(sizes).map(size => {
    if (size && size.source_url) {
      srcSet.push(`${size.source_url} ${size.width}w`)
    }
  })
  srcSet = srcSet.join(", ")

  if (type === "fixed") {
    props.fixed = {
      width,
      height,
      src: props.imageSrc,
      srcSet,
      sizes: props.sizes,
    }
    if (props.imageAlt) {
      props.alt = props.imageAlt
      delete props.imageAlt
    }
    delete props.sizes
  }
  if (type === "fluid") {
    props.fluid = {
      aspectRatio: Math.round((width / height) * 100) / 100,
      src: props.imageSrc,
      srcSet,
      sizes: props.sizes,
    }
    delete props.sizes
    if (props.imageAlt) {
      props.alt = props.imageAlt
      delete props.imageAlt
    }
  }
  if (type === "figure") {
    props.width = width
    props.height = height
    props.imageSizes = props.sizes
    props.imageSrcSet = srcSet
  }

  delete props.type
  delete props.media
  delete props.size

  return (
    props.imageSrc && (
      <>
        {type === "figure" && <Figure {...props} />}
        {type === "simple" && <Image src={props.imageSrc} {...props} />}
        {type === "fixed" && <GatsbyImage {...props} />}
        {type === "fluid" && <GatsbyImage {...props} />}
      </>
    )
  )
}
const Image = styled.img``
