export function articleNormalizer(article) {
  if (!article) {
    return {
      id: 0,
      title: "",
      branch: null,
      artist: null,
      category: null,
      path: "#",
      acf: {},
    }
  }

  if (article.featured_media && article.featured_media.source_url) {
    article.imageSrc = article.featured_media.source_url
  }

  if (article.acf && article.acf.article_video) {
    article.videoSrc = article.acf.article_video
  }

  if (article.title) {
    article.titleHtml = article.title
  }

  // tweaks from search resutls
  if (!article.author) {
    article.author = {}
  }
  if (article.author_name) {
    article.author.name = article.author_name
  }
  if (article.author_path) {
    article.author.path = article.author_path
  }
  ///

  if (!article.path) {
    article.path = "#"
  }
  const { article_custom_link_url } = article.acf || {}

  article.url = !!article_custom_link_url ? article_custom_link_url : article.path
  article.urlTarget = !!article_custom_link_url ? '_blank' : '_self'

  return article
}

export function pageNormalizer(page) {
  if (!page) {
    return {
      title: "",
      content: "",
      path: "#",
      heroImageSrc: "",
    }
  }
  if (!page.acf) {
    page.acf = {}
  }
  page.title = page.title.replace(/&#038;/g, "&")
  page.heroImageSrc = page.featured_media && page.featured_media.source_url

  return page
}