import locationQueryParams from "src/utils/locationQueryParams"

const routesConfig = {
  search: () => "/search",
  power100: (query = {}) => {
    return `/power-100${locationQueryParams.stringify(query)}`
  },
  artistSingle: (path, query = {}) => {
    // Possible query keys: year
    return `${path}${locationQueryParams.stringify(query)}`
  },
  categoryListing: (path, query = {}) => {
    // Possible query keys: FILTER_ENUM | 'page'
    return `${path}${locationQueryParams.stringify(query)}`
  },
}

export default routesConfig
