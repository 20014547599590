import { useEffect, useRef } from "react"

export default function useIntersectionObserver({
  callback,
  elementRef,
  options,
  skip,
}) {
  const observer = useRef()

  function addIntersectionObserver(el) {
    const observer = new IntersectionObserver(changes => {
      changes.forEach(change => {
        callback(change)
      })
    }, options || {})

    observer.observe(el)
    return observer
  }

  useEffect(() => {
    const cleanup = () => {
      if (!elementRef.current || !observer.current) return
      observer.current.unobserve(elementRef.current)
    }

    if (skip) return cleanup;
    if (!elementRef.current) return

    if (!observer.current) {
      observer.current = addIntersectionObserver(elementRef.current)
    } else {
      observer.current.unobserve(elementRef.current)
      observer.current = addIntersectionObserver(elementRef.current)
    }

    return cleanup
  }, [skip])
}
