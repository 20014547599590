import React, { useState, useRef } from "react"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import { DFPSlotsProvider, AdSlot } from "react-dfp"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"
import FlexibleHeading from "src/components/FlexibleHeading"
import Container from "src/components/Container"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"

// google ad manager code:  props
const ads = {
  "homepage/archive": {
    placeholder: "/images/mpu.png",
    sizes: [[300, 250]],
  },
  "homepage/grid_1": {
    placeholder: "/images/mpu.png",
    sizes: [[300, 250]],
  },
  "homepage/grid_2": {
    placeholder: "/images/mpu.png",
    sizes: [[300, 600]],
  },
  "homepage/grid_3": {
    placeholder: "/images/mpu.png",
    sizes: [[300, 250]],
  },
  "homepage/before_footer": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "homepage/before_grid_1": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "homepage/before_grid_2": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "homepage/before_header": {
    placeholder: "/images/leaderboard.png",
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "page/before_header": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "page/sidebar": {
    sizes: [[300, 600],[300, 250]],
  },
  "page/before_footer": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "article/before_header": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "article/sidebar": {
    sizes: [[300, 600],[300, 250]],
  },
  "article/recent": {
    sizes: [[300, 250]],
  },
  "article/before_footer": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "artist/sidebar": {
    sizes: [[300, 600],[300, 250]],
  },
  "artist/before_header": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "artist/before_footer": {
    sizes: [[728, 90]],
  },
  "power100/before_header": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "power100/before_footer": {
    sizes: [[728, 90]],
  },
  "author/before_header": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "author/before_footer": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "author/grid": {
    sizes: [[300, 250]],
  },
  "category/before_header": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "category/before_footer": {
    sizes: [
      [300, 250],
      [728, 90],
      [1112, 278],
    ],
  },
  "category/grid": {
    sizes: [[300, 250]],
  },
}

export default ({ type, className }) => {
  const { wordpressAcfOptions, site } = useStaticQuery(graphql`
    query AdsOverrideQuery {
      wordpressAcfOptions {
        options {
          site_ads_override {
            ad_placement
            text
            url
            open_in_new_window
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const [observed, setObserved] = useState(false)

  const wrapper = useRef()
  const textAd = useRef()

  const siteAdsOverride = get(
    wordpressAcfOptions,
    "options.site_ads_override",
    []
  ).find(ad => ad.ad_placement === type)

  function onTextAdScaleChange(scale) {
    const height = textAd.current.offsetHeight * scale
    textAd.current.style.marginBottom = height + "px"
  }

  useIntersectionObserver({
    callback: change => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: wrapper,
  })

  return (
    <Wrapper ref={wrapper} data-type={type} className={className}>
      <Inner>
        {ads[type] && observed && (
          <DFPSlotsProvider
            dfpNetworkId={process.env.GATSBY_AD_MANAGER_ID}
            collapseEmptyDivs
            lazyLoad={true}
            targetingArguments={{ url: site.siteMetadata.siteUrl }}
          >
            <AdSlot adUnit={type} sizes={ads[type].sizes} />
          </DFPSlotsProvider>
        )}
        {siteAdsOverride && siteAdsOverride.text && (
          <TextAdContainer>
            <TextAdWrapper ref={textAd}>
              <FlexibleHeading onScaleChange={onTextAdScaleChange}>
                {siteAdsOverride.url ? (
                  <a
                    href={siteAdsOverride.url}
                    target={
                      siteAdsOverride.open_in_new_window ? "_blank" : "_self"
                    }
                  >
                    {siteAdsOverride.text}
                  </a>
                ) : (
                  siteAdsOverride.text
                )}
              </FlexibleHeading>
            </TextAdWrapper>
          </TextAdContainer>
        )}
        {!siteAdsOverride && <Badge>Advertisement</Badge>}
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .-layout-homepage-1 & {
    grid-row: 2/3;
    grid-column: 7/9;
  }

  .-layout-homepage-2 & {
    grid-row: 1/2;
    grid-column: 7/9;
  }

  .-layout-homepage-3 & {
    grid-row: 2/3;
    grid-column: 1/4;
  }

  .-layout-homepage & {
    grid-row: 2/3;
    grid-column: 1/4;
  }

  .-layout-category & {
    grid-row: 2/3;
    grid-column: 1/2;
  }

  .-layout-category[data-items-count="1"] & {
    grid-row: 1/2;
    grid-column: 2/3;
  }

  .-layout-category[data-items-count="2"] & {
    grid-row: 1/2;
    grid-column: 3/4;
  }

  .-layout-category[data-items-count="4"] & {
    grid-row: 1/2;
    grid-column: 4/5;
  }

  &[data-type="Leaderboard"] {
    padding: 10px 0;
    background: #ececec;
  }

  &[data-type="homepage/before_header"],
  &[data-type="page/before_header"],
  &[data-type="category/before_header"],
  &[data-type="author/before_header"],
  &[data-type="artist/before_header"],
  &[data-type="power100/before_header"],
  &[data-type="article/before_header"] {
    display: flex;
    justify-content: center;
    overflow: hidden;
    background: #ececec;

    .adBox {
      max-width: 100vw;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .-search-open & {
      display: none;
    }
  }

  &[data-type="homepage/before_footer"],
  &[data-type="homepage/before_grid_1"],
  &[data-type="homepage/before_grid_2"],
  &[data-type="page/before_footer"],
  &[data-type="article/before_footer"],
  &[data-type="author/before_footer"],
  &[data-type="category/before_footer"] {
    iframe {
      margin-bottom: 85px;
    }
    text-align: center;
  }

  &.-hidden {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    margin: 0 !important;

    .-layout-slideshow &,
    .-layout-category &,
    &[data-type="Half page"] {
      display: none;
    }
  }

  .-video-fullscreen & {
    display: none;
  }

  @media (min-width: ${breakpoints.tablet -
      1}px) and (max-width: ${breakpoints.desktop - 1}px) {
    &[data-type="homepage/grid_1"] {
      height: 193px;
      transform: scale(0.773333333);
      transform-origin: 0 0;
    }

    &[data-type="homepage/grid_3"],
    &[data-type="category/grid"] {
      height: 194px;
      transform: scale(0.776666667);
      transform-origin: 0 0;
    }
  }

  @media (max-width: ${breakpoints.desktop - 1}px) {
    &[data-type="article/sidebar"],  &[data-type="artist/sidebar"], &[data-type="page/sidebar"]{
      text-align:center;
    }
  }

  ${media.desktopMedium} {
    .-layout-homepage-3 & {
      grid-column: 1/3;
    }
    .-layout-homepage & {
      grid-column: 1/3;
    }
  }
`

const Inner = styled.div`
  position: relative;

  [data-type="Leaderboard"] & {
    max-width: 728px;
    margin: 0 auto;
  }
`

const Image = styled.img`
  display: block;
`

const TextAdContainer = styled(Container)`
  padding-bottom: 1px;

  [data-type="homepage/before_grid_1"] & {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  [data-type="homepage/before_grid_2"] & {
    margin-bottom: 30px;
  }

  ${media.tablet} {
    [data-type="homepage/before_grid_1"] & {
      margin-top: 76px;
      margin-bottom: 85px;
    }

    [data-type="homepage/before_grid_2"] & {
      margin-top: -40px;
      margin-bottom: 50px;
    }
  }
`

const TextAdWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Badge = styled.div`
  visibility: hidden;
  height: 18px;
  padding: 0 5px;
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 9px;
  line-height: 18px;
  color: rgba(20, 20, 20, 0.6);
  pointer-events: none;

  [data-type="homepage/before_header"] &,
  [data-type="page/before_header"] &,
  [data-type="category/before_header"] &,
  [data-type="aurhor/before_header"] &,
  [data-type="article/before_header"] & {
    top: 12px;
  }

  ${Inner}:hover & {
    visibility: visible;
  }
`
