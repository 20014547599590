import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import moment from 'moment';

export default ({ items }) => {
  return (
    <Wrapper>
      {items &&
        items.map(({ author, content, date }, index) => (
          <Post key={index}>
            <PostAuthor href={author.url} target="_blank">
              @{author.name}
            </PostAuthor>
            <PostDate>{moment(date).fromNow()}</PostDate>
            <PostContent dangerouslySetInnerHTML={{ __html: content }} />
          </Post>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`

const Post = styled.article`
  display: flex;
  flex-wrap: wrap;
  width: 0;
  min-width: 300px;
  margin-right: 10px;
  padding: 19px;
  border: 1px solid rgba(128, 128, 128, 0.2);
  font-size: 14px;
  line-height: 20px;
  color: ${p => p.theme.color.grey};

  a {
    color: ${p => p.theme.color.text};

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 370px) {
    min-width: 245px;
  }

  ${media.tablet} {
    min-width: 280px;
  }

  ${media.desktop} {
    min-width: calc((100% - 70px) / 8 * 2 + 10px);
  }
`

const PostAuthor = styled.a`
  margin-right: 16px;
  font-weight: 600;
`

const PostDate = styled.p``

const PostContent = styled.p`
  width: 100%;
  height: calc(100% - 30px);
  margin-top: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
`
