import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Img from "gatsby-image"
import variables from "src/styles/variables"

export default ({
  fluid,
  imageSrc,
  imageSrcSet,
  imageSizes,
  width,
  height,
  imageAlt,
  caption,
}) => {
  const maxWidth = Math.min(width, variables.containerWidth)
  const maxHeight = maxWidth / (width / height)

  return (
    <Wrapper className={caption ? "-has-caption" : ""}>
      {fluid && <Img fluid={fluid} alt={imageAlt} />}
      {imageSrc && (
        <ImageWrapper maxHeight={maxHeight}>
          <Image
            src={imageSrc}
            srcSet={imageSrcSet}
            sizes={imageSizes}
            alt={imageAlt}
            loading="lazy"
          />
        </ImageWrapper>
      )}
      {caption && <Caption dangerouslySetInnerHTML={{ __html: caption }} />}
    </Wrapper>
  )
}

const Wrapper = styled.figure`
  margin: 45px -${p => p.theme.sidePadding.phone}px;

  header + & {
    margin-bottom: 96px;

    &.-has-caption {
      margin-bottom: 70px;
    }
  }

  ${media.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`

const ImageWrapper = styled.div`
  background: rgba(0, 0, 0, 0.04);

  @media (min-width: 1280px) {
    min-height: ${p => p.maxHeight}px;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
`

const Caption = styled.figcaption`
  margin-top: 6px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
`
