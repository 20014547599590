import React from "react"
import styled from "styled-components"
// import { media } from "src/styles/breakpoints"
import Icon from "src/components/Icon"
import { useStaticQuery, graphql } from "gatsby"

export default ({ title, path, direction, className }) => {
  const { site } = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const url =
    path && path.includes("http")
      ? path
      : site.siteMetadata.siteUrl + (path || "")

  const items = [
    {
      label: "Share",
      icon: "facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      label: "Tweet",
      icon: "twitter",
      url: `http://twitter.com/share?text=${title}&url=${url}`,
    },
  ]

  return (
    <Wrapper
      className={
        className || "" + (direction === "horizontal" ? " -horizontal" : "")
      }
    >
      {items.map(({ label, icon, url }, index) => (
        <Item key={index}>
          <ItemLink
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            aria-label={label}
          >
            <StyledIcon name={icon} />
            <Label>{label}</Label>
          </ItemLink>
        </Item>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.ul``

const Item = styled.li`
  ${Wrapper}.-horizontal & {
    display: inline-block;

    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }
`

const ItemLink = styled.a`
  display: inline-block;
  height: 30px;
  position: relative;
  transition: 0.1s;

  ${Wrapper}.-horizontal & {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;

  [aria-label="Share"]${ItemLink} & {
    position: relative;
    top: -2px;
  }
`

const Label = styled.span`
  margin-left: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;

  ${Wrapper}.-horizontal & {
    margin-left: 0;
    margin-right: 12px;
  }
`
