import * as types from "src/store/actionTypes"

export const setSearchState = ({ searchState }) => ({
  type: types.SET_SEARCH_STATE,
  payload: {
    searchState,
  },
})

export const setSearchResults = ({ searchResults }) => ({
  type: types.SET_SEARCH_RESULTS,
  payload: {
    searchResults,
  },
})
