import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

export default props => {
  const { fitVertically, onScaleChange, className } = props
  const [scale, setScale] = useState(0)
  const el = useRef()

  function fitText() {
    setScale(1)
    const area = el.current.parentNode
    const areaRect = area.getBoundingClientRect()
    const elRect = el.current.getBoundingClientRect()
    const scaleX = areaRect.width / elRect.width
    const scaleY = fitVertically
      ? (areaRect.height / elRect.height) * 0.6
      : Infinity
    const scale = Math.min(scaleX, scaleY)
    setScale(scale)
    onScaleChange && onScaleChange(scale)
  }

  function handleResize() {
    fitText()
  }

  useEffect(() => {
    setTimeout(() => {
      fitText()
    }, 10)

    window.addEventListener("resize", handleResize, true)

    return () => {
      window.removeEventListener("resize", handleResize, true)
    }
  }, [])

  return (
    <Heading
      ref={el}
      className={className}
      style={{ transform: `scale(${scale})` }}
    >
      {props.children}
    </Heading>
  )
}

const Heading = styled.h3`
  max-width: 120px;
  transform-origin: center 0;
  font-weight: 600;
  font-size: 141px;
  line-height: 1;
  text-align: center;
  background: #fff;
  font-size: 10px;
`
