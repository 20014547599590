import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import Container from "src/components/Container"
import SectionSliderSocial from "src/components/SectionSliderSocial"
import SectionSliderStories from "src/components/SectionSliderStories"
import SocialMediaIcons from "src/components/SocialMediaIcons"
import { PrimaryTitle } from "src/components/Typography"
import Icon from "src/components/Icon"
import { useStaticQuery, graphql } from "gatsby"
import { articleNormalizer } from "src/utils/contentNormalizers"
import wpHttpClient from "src/utils/wpHttpClient"

export default ({ source, title, items }) => {
  const { recent, archive, wordpressAcfOptions } = useStaticQuery(graphql`
    query sliderQuery {
      recent: allWordpressPost(sort: { fields: date, order: DESC }, limit: 10) {
        nodes {
          ...ArticleFragment
        }
      }
      archive: allWordpressPost(
        sort: { fields: date, order: DESC }
        filter: { status: { eq: "archived" } }
        limit: 10
      ) {
        nodes {
          ...ArticleFragment
        }
      }
    }
  `)

  const recentStories = recent.nodes.map(articleNormalizer)
  const archiveStories = archive.nodes.map(articleNormalizer)
  const [twitterFeed, setTwitterFeed] = useState([])

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        const result = await wpHttpClient.get("air/v1/site/twitter_feed")
        setTwitterFeed(result.data)
      } catch (e) {}
    }
    if (source === "social-media") {
      fetchFeed();
    }
  }, [source])

  const [sliderPosition, setSlidePosition] = useState(0)
  const itemsCount = items
    ? items.length
    : (source === "social-media"
        ? twitterFeed
        : source === "archive"
        ? archiveStories
        : recentStories
      ).length
  const countInRow = source === "social-media" || source === "search" ? 4 : 3
  const isLast = sliderPosition + countInRow >= itemsCount

  function slide(direction) {
    let position = sliderPosition + (direction === "next" ? 1 : -1)
    const max = itemsCount - countInRow
    position = Math.min(Math.max(position, 0), max)
    setSlidePosition(position)
  }
  if(itemsCount === 0 && source === "social-media") return null;

  return (
    <Wrapper
      className={`-source-${source} -slider-position-${sliderPosition} ${
        isLast ? "-slider-position-last" : ""
      }`}
    >
      <StyledContainer>
        <Header>
          {title && <Title>{title}</Title>}
          {source === "social-media" && (
            <>
              <FeedTitle>Twitter</FeedTitle>
              <SocialMediaIcons dimmed={true} />
            </>
          )}
          <Navigation>
            <NavigationControl
              onClick={() => {
                slide("prev")
              }}
            >
              <NavigationControlIcon name="arrow-left" />
            </NavigationControl>
            <NavigationControl
              onClick={() => {
                slide("next")
              }}
            >
              <NavigationControlIcon name="arrow-right" />
            </NavigationControl>
          </Navigation>
        </Header>
        <Track>
          {source === "social-media" && (
            <SectionSliderSocial items={twitterFeed} />
          )}
          {source === "stories" && (
            <SectionSliderStories
              items={recentStories}
              adType="article/recent"
            />
          )}
          {source === "archive" && (
            <SectionSliderStories
              items={archiveStories}
              adType="homepage/archive"
            />
          )}
          {source === "search" && <SectionSliderStories items={items} size="small" />}
        </Track>
      </StyledContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  overflow: hidden;
  margin-bottom: 20px;

  &.-source-social-media {
    margin-top: -35px;
    margin-bottom: 35px;
    padding-top: 0;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    section[class*="SectionStoryGrid"] + & {
      margin-top: -20px;
    }
  }

  ${media.tablet} {
    margin-bottom: 85px;

    &.-source-social-media {
      margin-bottom: 105px;
    }
  }
`

const StyledContainer = styled(Container)``

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  user-select: none;

  ${Wrapper}.-source-search & {
    max-width: 190px;
  }
`

const FeedTitle = styled.h3`
  margin-right: 29px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const Navigation = styled.div`
  display: none;
  flex: 1;
  margin-right: -9px;
  text-align: right;

  .-source-stories &,
  .-source-archive & {
    position: relative;
    top: -12px;
  }

  ${media.desktop} {
    ${media.pointer} {
      display: block;
    }
  }
`

const NavigationControl = styled.button`
  width: 34px;
  height: 40px;
  padding: 0;
  transition: 0.1s;

  &:first-child {
    .-slider-position-0 & {
      opacity: 0.1;
    }
  }

  &:last-child {
    .-slider-position-last & {
      opacity: 0.1;
    }
  }
`

const NavigationControlIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`

const Title = styled(PrimaryTitle)`
  ${Wrapper}.-source-search & {
    font-size: 20px;
  }
`

const Track = styled.div`
  ${createTrackStyles()};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-left: -${p => p.theme.sidePadding.phone}px;
  margin-right: -${p => p.theme.sidePadding.phone}px;
  padding-left: ${p => p.theme.sidePadding.phone}px;
  padding-bottom: 10px;
  transition: transform 0.5s;

  ${media.tablet} {
    margin-left: -${p => p.theme.sidePadding.tablet}px;
    margin-right: -${p => p.theme.sidePadding.tablet}px;
    padding-left: ${p => p.theme.sidePadding.tablet}px;
  }

  ${media.desktop} {
    ${media.pointer} {
      overflow: visible;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    }
  }
`

function createTrackStyles() {
  let styles = ""

  for (let i = 0; i < 20; i += 1) {
    styles += `
    .-slider-position-${i} & {
      transform: translateX(
        calc(((100% - 70px) / 8 * 2 + 20px) * -${i})
      );
    }
    `
  }

  return css`
    ${styles}
  `
}
