import { useEffect } from "react"
import requestAnimation from "src/utils/requestAnimation"

const DEFAULT_OPTIONS = {
  runOnMount: false,
}

export default function useScrollListener({
  callback,
  effectArray = [],
  options = DEFAULT_OPTIONS,
}) {
  const animationCallback = requestAnimation(callback)

  function addListeners() {
    if (typeof window === "undefined") return
    window.addEventListener("scroll", animationCallback)
  }

  function removeListeners() {
    if (typeof window === "undefined") return
    window.removeEventListener("scroll", animationCallback)
  }

  useEffect(() => {
    if (options.runOnMount) {
      animationCallback()
    }
    addListeners()
    return removeListeners
  }, effectArray)
}
