import React, { useEffect, useRef, useState } from "react"

import { navigate } from "gatsby"
import { findDOMNode } from "react-dom"
import ReactPlayer from "react-player"
import screenfull from "screenfull"
import Icon from "src/components/Icon"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import styled from "styled-components"
import { isIOS } from "src/utils/platform"

export default ({
  src,
  autoplay,
  loop,
  automute,
  poster,
  standalone,
  title,
  path,
  redirect,
  className,
  onPlayStateChange = () => {},
}) => {
  const [url, setUrl] = useState(null)
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(true)
  const [volume, setVolume] = useState(automute ? 0 : 1)
  const [seeking, setSeeking] = useState(false)
  const [played, setPlayed] = useState(0)
  const [duration, setDuration] = useState(0)
  const [fullscreen, setFullscreen] = useState(false)
  const [focusMode, setFocusMode] = useState(false)
  const [playbackInitialized, setPlaybackInitialized] = useState(false)
  const [observed, setObserved] = useState(false)
  const [isFullscreenBtnVisible, setIsFullscreenBtnVisible] = useState(false)
  const wrapper = useRef()
  const player = useRef()
  const seekingRef = useRef(seeking)
  const timeoutRef = useRef()
  const hostType = src.includes("youtu")
    ? "youtube"
    : src.includes("vimeo")
    ? "vimeo"
    : "custom"
  seekingRef.current = seeking

  function handlePlayPause() {
    setPlaying(!playing)
  }

  function handleSeekMouseDown(e) {
    setSeeking(true)
  }

  function handleSeekMouseUp(e) {
    setSeeking(false)
    player.current.seekTo(parseFloat(e.target.value))
  }

  function handleSeekChange(e) {
    setPlayed(parseFloat(e.target.value))
  }

  function handleProgress(state) {
    if (!seekingRef.current) {
      setPlayed(state.played)
    }
    if (state.playedSeconds > 0) {
      setPlaybackInitialized(true)
    }
  }

  function handleDuration(duration) {
    setDuration(duration)
  }

  function handleVideoClick(e) {
    const controlClick = e.target.closest("div[class^='VideoPlayer__Controls']")
    if (redirect && !controlClick) {
      if (e.type === "click") {
        const internal = /^\/(?!\/)/.test(redirect)
        if (internal) {
          navigate(redirect)
        } else {
          window.open(redirect, "_blank")
        }
      }
    } else {
      if (!e.target.closest("button[class^='VideoPlayer__MuteButton']")) {
        setMuted(false)
        setVolume(1)
        if (!controlClick) {
          setPlaying(true)
        }
      }
    }
  }

  function handleMouseMove() {
    if (fullscreen) {
      setFocusMode(false)
      ;(() => {
        if (typeof timeoutRef.current !== "undefined") {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
          setFocusMode(true)
        }, 2000)
      })()
    }
  }

  function handleMuteButton(e) {
    const isMuted = muted
    setMuted(!isMuted)
    setVolume(isMuted ? 1 : 0)
    if (!e.target.closest("div[class^='VideoPlayer__Controls']")) {
      setPlaying(isMuted)
    }
  }

  function handleFullscreenButton() {
    if (isIOS()) {
      switch (hostType) {
        case "vimeo":
          player.current.getInternalPlayer().requestFullscreen()
          break

        case "youtube":
          // player.current.getInternalPlayer().g.requestFullscreen()
          break

        case "custom":
          player.current.getInternalPlayer().webkitEnterFullScreen()
          break

        default:
          break
      }
    } else {
      const node = findDOMNode(wrapper.current)

      if (screenfull.isEnabled) {
        if (fullscreen) {
          screenfull.exit(node)
        } else {
          screenfull.request(node)
        }
        screenfull.off("change", () => {})
        screenfull.on("change", () => {
          setFullscreen(screenfull.isFullscreen)
          document.body.classList.toggle(
            "-video-fullscreen",
            screenfull.isFullscreen
          )
        })
      }
    }
  }

  function format(seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }

  function pad(string) {
    return ("0" + string).slice(-2)
  }

  useEffect(() => {
    setUrl(src)
    if (autoplay && !(window.innerWidth <= 1024 && standalone)) {
      setPlaying(true)
    }
  }, [])

  useEffect(() => {
    onPlayStateChange(playing)
  }, [playing])

  useIntersectionObserver({
    callback: change => {
      if (change.isIntersecting) {
        setObserved(true)
      } else {
        setObserved(false)
      }
    },
    elementRef: wrapper,
  })

  useEffect(() => {
    if (typeof window === "undefined") return

    setIsFullscreenBtnVisible(
      isIOS()
        ? (() => {
            switch (hostType) {
              case "vimeo":
              case "custom":
                return playbackInitialized

              case "youtube":
                return false

              default:
                break
            }
          })()
        : screenfull.isEnabled
    )
  }, [playbackInitialized])

  return (
    <Wrapper
      ref={wrapper}
      className={
        (className || "") +
        ` -host-${hostType}` +
        (standalone ? " -standalone" : " -embedded") +
        (playing ? " -playing" : " -paused") +
        (muted ? " -muted" : " -unmuted") +
        (focusMode ? " -focus-mode" : "") +
        (playbackInitialized ? " -playback-initialized" : "") +
        (fullscreen ? " -fullscreen" : "") +
        (redirect ? " -redirect" : "")
      }
      onClick={handleVideoClick}
      onTouchStart={handleVideoClick}
      onMouseMove={handleMouseMove}
    >
      <ReactPlayer
        ref={player}
        url={url}
        playing={observed && playing}
        progressInterval={100}
        loop={loop}
        muted={muted}
        volume={volume}
        width="100%"
        height="100%"
        style={{ position: "relative", zIndex: 0 }}
        playsinline
        config={{ file: { attributes: { playsInline: true } } }}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
      />
      {poster && <Poster src={poster} />}
      <Controls>
        <PauseButton onClick={handlePlayPause}>
          {playing ? <StyledIcon name="pause" /> : <StyledIcon name="play" />}
        </PauseButton>
        <SeekControlWrapper>
          <SeekControlRange style={{ width: `${played * 100}%` }} />
          <SeekControl
            type="range"
            min={0}
            max={1}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onTouchStart={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
            onTouchEnd={handleSeekMouseUp}
          />
        </SeekControlWrapper>
        <Time>
          <Elapsed>{format(duration * played)}</Elapsed>/
          <Duration>{format(duration)}</Duration>
        </Time>
        <MuteButton onClick={handleMuteButton}>
          {muted ? (
            <MuteButtonIcon name="sound-off" />
          ) : (
            <MuteButtonIcon name="sound-on" />
          )}
        </MuteButton>

        <FullscreenButton
          onClick={handleFullscreenButton}
          className={isFullscreenBtnVisible ? "-visible" : undefined}
        >
          {fullscreen ? (
            <FullscreenButtonIcon name="fullscreen-off" />
          ) : (
            <FullscreenButtonIcon name="fullscreen" />
          )}
        </FullscreenButton>

        {path && <StyledSocialMediaSharing title={title} path={path} />}
      </Controls>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: calc((100vw - ${p => p.theme.sidePadding.phone}px * 2) * 0.5625);
  overflow: hidden;
  margin: 45px 0;
  position: relative;
  background: #000;

  header + & {
    margin-bottom: 96px;
  }

  video {
    object-fit: cover;
    float: left;
    pointer-events: none;
  }

  iframe {
    pointer-events: none;
  }

  &.-standalone.-host-custom {
    height: auto;
  }

  &.-redirect {
    cursor: pointer;
  }

  &.-fullscreen {
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
  }

  ${media.tablet} {
    &.-standalone {
      height: calc((100vw - ${p => p.theme.sidePadding.tablet}px * 2) * 0.5625);
    }
  }

  ${media.desktop} {
    &.-standalone {
      height: calc(
        (100vw - ${p => p.theme.sidePadding.desktop}px * 2) * 0.5625
      );
    }
  }

  @media (min-width: 1280px) {
    &.-standalone {
      height: 691.875px;
    }
  }
`

const Poster = styled.div`
  display: none;
  background: url(${p => p.src}) center center / cover;

  &,
  &::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
  }

  ${Wrapper}.-host-custom & {
    display: block;
  }

  ${Wrapper}.-playback-initialized & {
    visibility: hidden;
  }
`

const Controls = styled.div`
  transition: 0.25s;
  transition-property: visibility, opacity;

  ${Wrapper}.-standalone & {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      pointer-events: none;
    }
  }

  /* prettier-ignore */
  ${Wrapper}.-playing:not(:hover) &,
  ${Wrapper}.-focus-mode.-fullscreen & {
    visibility: hidden;
    opacity: 0;
  }
`

const PauseButton = styled.button`
  width: 12px;
  height: 10px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  color: #fff;

  &::before {
    content: "";
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
  }

  ${media.tablet} {
    bottom: 40px;
    left: 40px;
  }
`

const StyledIcon = styled(Icon)`
  width: 12px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
`

const SeekControlWrapper = styled.div`
  position: absolute;
  width: calc(100% - 145px);
  height: 10px;
  bottom: 20px;
  left: 50px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);

  ${media.tablet} {
    width: calc(100% - 195px);
    bottom: 40px;
    left: 70px;
  }
`

const SeekControlRange = styled.div`
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  pointer-events: none;
`

const SeekControl = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  oerflow: hidden;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
  }

  &::-webkit-slider-thumb {
    visibility: hidden;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: 100%;
  }

  &::-moz-range-progress {
    background: transparent;
  }

  &::-moz-range-thumb {
    visibility: hidden;
  }

  &::-ms-track {
    width: 100%;
    height: 100%;
    border: 0;
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: transparent;
  }

  &::-ms-fill-upper {
    background: transparent;
  }

  &::-ms-thumb {
    visibility: hidden;
  }
`

const Time = styled.p`
  position: absolute;
  right: 20px;
  bottom: 19px;
  font-size: 14px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;

  ${media.tablet} {
    right: 40px;
    bottom: 39px;
  }
`

const Elapsed = styled.span`
  margin-right: 1px;
`

const Duration = styled.span`
  margin-left: 1px;
`

const MuteButton = styled.button`
  width: 40px;
  height: 34px;
  position: absolute;
  top: 18px;
  left: 10px;
  color: #fff;

  ${media.tablet} {
    width: 60px;
    left: 20px;
  }
`

const MuteButtonIcon = styled(Icon)`
  width: 17px;
  height: 16px;
  pointer-events: none;

  ${Wrapper}.-muted & {
    position: relative;
    left: -4px;
  }
`

const FullscreenButton = styled.button`
  width: 40px;
  height: 34px;
  position: absolute;
  top: 18px;
  left: 50px;
  color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;

  &.-visible {
    opacity: 1;
    pointer-events: auto;
  }

  ${media.tablet} {
    width: 60px;
    top: 52px;
    left: 20px;
  }
`

const FullscreenButtonIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  position: relative;
  left: -3px;
  pointer-events: none;

  ${Wrapper}.-fullscreen & {
    width: 12px;
    height: 12px;
  }
`

const StyledSocialMediaSharing = styled(SocialMediaSharing)`
  position: absolute;
  top: 25px;
  right: 20px;
  color: #fff;

  span {
    display: none;
  }

  ${media.tablet} {
    right: 30px;
  }
`
