export default {
  stringify(obj) {
    let queryString = ''
  
    if (Object.values(obj).some(Boolean)) {
      queryString = '?' + Object.entries(obj).reduce((acc, item) => {
        if (item[1] || typeof(item[1]) === 'number') {
          acc.push(item.join('='))
        }
        return acc
      }, []).join('&')
    }
  
    return queryString
  },
  
  parse(search) {
    const res = {}
  
    if (typeof window === "undefined") return res
  
    const _search = search || window.location.search
  
    if (!_search) return res
  
    const arr = _search.slice(1).split("&")
    arr.forEach(item => {
      const [key, value] = item.split("=")
      res[key] = decodeURIComponent(value)
    })
  
    return res
  }
}