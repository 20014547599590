import React from "react"
import styled from "styled-components"
import noResultsBg from "src/assets/images/no-results-bg.gif"
import { PrimaryTitle } from "src/components/Typography"

export default function NoResultsInfo() {
  return (
    <NoResults>
      <NoResultsText>No results</NoResultsText>
    </NoResults>
  )
}

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
  width: 300px;
  height: 265px;
  background-image: url(${noResultsBg});
  background-size: cover;
`

const NoResultsText = styled(PrimaryTitle)`
  font-size: 28px;
  color: #fff;
`
