import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import useScrollListener from "src/hooks/useScrollListener"
import Icon from "src/components/Icon"
import HeaderNavigation from "src/components/HeaderNavigation"
import HeaderSearch from "src/components/HeaderSearch"
import { PrimaryTitle } from "src/components/Typography"
import { connectStateResults, InstantSearch } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { getIndexName } from "src/utils/algolia"
import { setSearchState } from "src/store/search/actions"
import { useDispatch, useSelector } from "react-redux"

const HeaderSearchConnected = connectStateResults(HeaderSearch)

export default ({ dark, scaleLogo }) => {
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  const dispatch = useDispatch()
  const { searchState } = useSelector(state => state.search)
  const [logoLarge, setLogoLarge] = useState(scaleLogo)
  const [darkByScroll, setDarkByScroll] = useState(false)
  const [navVisible, setNavVisible] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const wrapper = useRef()
  let breakpoint = 1

  function handleScroll() {
    if (!dark && breakpoint) {
      setDarkByScroll(window.scrollY > breakpoint)
    }
    if (scaleLogo) {
      setLogoLarge(window.scrollY === 0)
    }
  }

  function onNavToggle(visible) {
    setNavVisible(visible)
  }

  function onSearchToggle(visible) {
    setSearchOpen(visible)
  }

  function handleSearchStateChange(searchState) {
    dispatch(setSearchState({ searchState }))
  }

  useScrollListener({
    callback: handleScroll,
  })

  useEffect(() => {
    if (!dark) {
      const firstSection = wrapper.current.parentNode.childNodes[2]
      if (firstSection) {
        breakpoint = firstSection.offsetTop - 56 - 70
      }
    }
  }, [])

  return (
    <Wrapper
      ref={wrapper}
      className={
        (dark || darkByScroll || searchOpen ? "-dark" : "") +
        (logoLarge ? " -logo-large" : "") +
        (navVisible ? " -nav-visible" : "") +
        (searchOpen ? " -search-open" : "")
      }
    >
      <HeaderNavigation onNavToggle={onNavToggle} />
      <StyledLink to="/">
        <LogoIcon name="logo" />
      </StyledLink>
      <InstantSearch
        searchClient={searchClient}
        indexName={getIndexName("Posts")}
        searchState={searchState}
        onSearchStateChange={handleSearchStateChange}
      >
        <HeaderSearchConnected onSearchToggle={onSearchToggle} />
      </InstantSearch>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  color: #fff;

  &.-dark {
    background: #fff;
    color: #000;
  }

  ${media.tablet} {
    padding: 0 10px;
  }
`

const StyledLink = styled(Link)`
  align-self: center;
  position: relative;
  z-index: 2;

  &[aria-current="page"] {
    pointer-events: none;
  }
`

const LogoIcon = styled(Icon)`
  width: 143px;
  height: 23px;
  transition: transform 0.25s;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${Wrapper}.-nav-visible & {
      display: none;
    }
    .-search-open &{
      display:none;
     }
  }

  ${media.tablet} {
    ${Wrapper}.-logo-large & {
      transform: scale(2);
    }
  }
`
