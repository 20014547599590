const { htmlToText } = require('html-to-text');

const postQuery = `{
    posts: allWordpressPost {
      edges {
        node {
            objectID: wordpress_id
            title
            category {
                wordpress_id
                name
            }
            categories {
              wordpress_id
              name
            }
            path
            year
            month
            author {
                name
                path
            }
            excerpt
            content
            featured_media {
              source_url
              caption
              alt_text
              media_details {
                width
                height
                sizes {
                  thumbnail {
                    source_url
                    width
                    height
                  }
                  medium {
                    source_url
                    width
                    height
                  }
                  large {
                    source_url
                    width
                    height
                  }
                }
              }
            }
            branch {
              name
            }
            acf {
              article_artist { 
                title
              }
            }
            date(formatString: "DD MMMM YYYY")
            timestamp: date(formatString: "X")
            modified
        }
      }
    }
  }
`
const ArtistsQuery = `
{
    artists: allWordpressWpArtist {
      edges {
        node {
          objectID: wordpress_id
          title
          path
          content
          excerpt
          artist_category {
            name
          }
          nationality {
            name
          }
          power_100_edition {
            name
          }
          acf {
            artist_power_100 {
              place
              edition {
                wordpress_id
                name
              }
            }
          }
          featured_media {
            source_url
            caption
            alt_text
            media_details {
              width
              height
              sizes {
                thumbnail {
                  source_url
                  width
                  height
                }
                medium {
                  source_url
                  width
                  height
                }
                large {
                  source_url
                  width
                  height
                }
              }
            }
          }
          modified
        }
      }
    }
  }`

const flatten = arr =>
  arr.map(({ node: { ...rest } }) => ({
    ...rest,
    content: htmlToText(rest.content, {
      wordwrap: false
    }).slice(0, 6000) // limit to 6KB
  }))

const artistsSettings = {
  attributesForFaceting: [
    "title",
    "artist_category.name",
    "power_100_edition.name",
    "nationality.name",
  ],
  searchableAttributes: ["title"],
}

const postsSettings = {
  attributesForFaceting: [
    "branch.name",
    "author.name",
    "category.wordpress_id",
    "categories.wordpress_id",
    "month",
    "year",
    "acf.article_artist.title",
  ],
  searchableAttributes: ["title,categories.name,author.name",'branch.name,year,acf.article_artist.title', "excerpt,content"],
  ranking:["typo","geo","words","filters","proximity","attribute","exact","custom"]
}

function getIndexName(forItem) {
  const items = ["Posts", "Artists"]
  const prefix = process.env.GATSBY_ALGOLIA_INDEX || ""

  if (items.indexOf(forItem) === -1) {
    console.error("algolia.js / getIndexName / forItem not found!")
  }

  return prefix + forItem
}

const queries = [
  {
    query: postQuery,
    transformer: ({ data }) => flatten(data.posts.edges),
    indexName: getIndexName("Posts"),
    settings: postsSettings,
  },
  {
    query: ArtistsQuery,
    transformer: ({ data }) => flatten(data.artists.edges),
    indexName: getIndexName("Artists"),
    settings: artistsSettings,
  },
]

module.exports = {
  getIndexName,
  queries,
}
