import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import Media from "src/components/Media"
import Link from "src/components/Link"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import VideoPlayer from "src/components/VideoPlayer"
import { articleNormalizer } from "src/utils/contentNormalizers"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { getSizes } from "src/utils/images"

export default ({ data, layout, index, size = "default" }) => {
  const {
    id,
    title,
    excerpt,
    author,
    category,
    branch,
    date,
    path,
    url,
    urlTarget,
    imageSrc,
    videoSrc,
    featured_media,
    acf: {
      article_collaboration,
      article_custom_link_url,
      article_custom_html_snippet,
      article_highlight,
    },
  } = articleNormalizer(data)

  const [observed, setObserved] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [imageMedia, setImageMedia] = useState({})
  const wrapper = useRef()

  const allowVideo =
    (layout === "homepage-1" && index === 3) ||
    (layout === "homepage-3" && index === 0) ||
    (layout === "homepage" && index === 0)

  function getClassNames() {
    let className = ""
    if (article_highlight) {
      className += " -highlight"
    }
    if (
      (layout === "homepage-1" && index > 3) ||
      (layout === "homepage-2" && index > 1) ||
      (layout === "homepage-3" && index > 1) ||
      (layout === "homepage" && index > 1) ||
      layout === "slideshow" ||
      layout === "category"
    ) {
      className += " -text-under-img"
    }
    if (
      (layout === "homepage-1" && index === 3) ||
      (layout === "homepage-2" && index < 2) ||
      (layout === "homepage-3" && index < 2) ||
      (layout === "homepage" && index < 2)
    ) {
      className += " -text-on-bg"
    }
    if (videoSrc && allowVideo) {
      className += " -video"
      if (playing) {
        className += " -playing"
      }
    }

    className += ` story-teaser-${id}`
    className += ` -size-${size}`
    return className
  }

  // function getImageSize() {
  //   let size =
  //     (layout === "homepage-1" && index === 3) ||
  //     (layout === "homepage-1" && index === 7) ||
  //     (layout === "homepage-2" && index < 2) ||
  //     (layout === "homepage-3" && index < 2)
  //       ? "large"
  //       : "medium"
  //   return size
  // }

  const haveCollaboration = layout.includes("homepage") && article_collaboration

  useIntersectionObserver({
    callback: change => {
      if (change.isIntersecting) {
        setObserved(true)
        setImageMedia(featured_media)
      }
    },
    elementRef: wrapper,
    skip: !featured_media,
  })

  return (
    <Wrapper ref={wrapper} className={getClassNames()}>
      <Inner>
        {allowVideo && videoSrc && observed && (
          <StyledVideoPlayer
            src={videoSrc}
            autoplay={true}
            loop={true}
            automute={true}
            title={title}
            path={!article_custom_link_url ? path : null}
            redirect={url}
            onPlayStateChange={value => setPlaying(value)}
          />
        )}
        <Text>
          <Title>
            <Link
              to={url}
              target={urlTarget}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Title>
          <Meta>
            {author && author.name && (
              <MetaAuthor to={author.path}>{author.name}</MetaAuthor>
            )}
            {haveCollaboration && (
              <MetaCollaboration>{article_collaboration}</MetaCollaboration>
            )}
            {category && layout !== "category" && !haveCollaboration && (
              <MetaCategory>
                {category.path ? (
                  <Link to={category.path}>{category.name}</Link>
                ) : (
                  category.name || category
                )}
              </MetaCategory>
            )}
            {branch && !layout.includes("homepage") && (
              <MetaBranch>{branch.name}</MetaBranch>
            )}
            {date && !haveCollaboration && <MetaDate>{date}</MetaDate>}
          </Meta>
          <Description dangerouslySetInnerHTML={{ __html: excerpt }} />
          {article_custom_html_snippet && (
            <div
              dangerouslySetInnerHTML={{ __html: article_custom_html_snippet }}
            />
          )}
        </Text>

        {(!allowVideo || !videoSrc) && (
          <ImageLink to={url} target={urlTarget}>
            {featured_media ? (
              <Image
                type="fixed"
                media={
                  ["category", "search", "slideshow"].includes(layout)
                    ? featured_media
                    : imageMedia
                }
                size="wordpress_2048x2048"
                sizes={getSizes(
                  layout.includes("homepage")
                    ? { default: "455px", xl: "33vw" }
                    : layout === "slideshow"
                    ? { default: "300px" }
                    : { default: "300px", xl: "25vw", lg: "33vw" }
                )}
                lazy={true}
              />
            ) : (
              <ImagePlaceholder />
            )}
          </ImageLink>
        )}
      </Inner>
    </Wrapper>
  )
}

const Inner = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;

  ${media.tablet} {
    flex-direction: column;
    align-items: stretch;
  }
`

const Text = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  margin-left: 10px;
  position: relative;
  z-index: 1;
  transition: 0.25s;
  transition-property: visibility, opacity;

  ${media.tablet} {
    margin-left: 0;
  }

  ${media.desktop} {
    display: block;
    margin-bottom: 25px;
  }
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;

  ${media.tablet} {
    margin-bottom: 12px;
  }
`

const Meta = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;

  > * {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  > :nth-child(n + 2) {
    color: rgba(0, 0, 0, 0.5);
  }
`

const MetaAuthor = styled(Link)`
  font-weight: 600;
`

const MetaCategory = styled.span``

const MetaBranch = styled.span`
  section[class^="SectionStoryGrid"] ~ section[class^="SectionSlider"] & {
    display: none;
  }
`

const MetaCollaboration = styled.span`
  display: none;

  ${media.desktop} {
    display: inline-block;
  }
`

const MetaDate = styled.span`
  display: none;

  ${media.desktop} {
    display: inline-block;
  }
`

const Description = styled.p`
  display: none;
  font-size: 15px;
  line-height: 20px;
  font-family: ${p => p.theme.font.secondary};
  color: ${p => p.theme.color.grey};

  ${media.desktop} {
    display: inline-block;
  }
`

const ImageLink = styled(Link)`
  ${media.tablet} {
    height: 194px;
  }

  ${media.desktop} {
    height: 250px;
  }
`

const Image = styled(Media)`
  width: calc(
    (100vw - ${p => p.theme.sidePadding.phone}px * 2 - 40px) / 5 * 2
  ) !important;
  height: calc(
    (100vw - ${p => p.theme.sidePadding.phone}px * 2 - 40px) / 5 * 2 * 0.762712
  ) !important;
  float: left;

  ${media.tablet} {
    width: 100% !important;
    height: 194px !important;
  }

  ${media.desktop} {
    height: 250px !important;
  }
`

const ImagePlaceholder = styled.div`
  width: calc((100vw - ${p => p.theme.sidePadding.phone}px * 2 - 40px) / 5 * 2);
  height: calc(
    (100vw - ${p => p.theme.sidePadding.phone}px * 2 - 40px) / 5 * 2 * 0.762712
  );
  background: #f5f5f5;

  ${media.tablet} {
    width: 100%;
    height: 194px;
  }

  ${media.desktop} {
    height: 250px;
  }
`

const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;

  &.-playing.-unmuted ~ ${Text} {
    visibility: hidden;
    opacity: 0;
  }
`

const Wrapper = styled.article`
  margin-bottom: 20px;
  position: relative;
  &.-highlight {
       ${MetaCategory}{
        color: rgb(205, 62,62);
      }
  }
  &.-text-on-bg {
    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    &::before {
      background: rgba(0, 0, 0, 0.2);
    }

    &::after {
      opacity: 0.3;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    ${Text} {
      position: absolute;
      left: 40px;
      right: 40px;
      bottom: 30px;
      color: #fff;
    }

    ${Meta} {
      > :nth-child(n + 2) {
        color: rgba(255, 255, 255, 0.5);
      }
    }

 

    ${Description} {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &.-video {
    ${Text} {
      bottom: 40px;
    }
    &::before, &::after {
      transition: 0.25s;
      transition-property: visibility, opacity;
    }
    &.-playing:not(:hover){
      &::before, &::after, ${Text} {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &.-text-on-bg.-video {
    ${Text} {
      bottom: 51px;
      pointer-events: none;
    }
  }

  .-layout-homepage-3 &:nth-child(1) {
    &.-video {
      height: calc((100vw - ${p => p.theme.sidePadding.phone}px * 2) * .5625);

      ${Inner} {
        height: 100%;
      }
    }
  }

  .-layout-homepage &:nth-child(1) {
    &.-video {
      height: calc((100vw - ${p => p.theme.sidePadding.phone}px * 2) * .5625);

      ${Inner} {
        height: 100%;
      }
    }
  }

  .-layout-homepage-1 &:nth-child(4) {
    &.-video {
      @media (min-width: 1280px) {
        height: auto !important;
        padding-bottom: calc(1080 / 1920 * 100%);
      }
      @media (max-width: ${breakpoints.tablet - 1}px) {
        height: auto !important;
        padding-bottom: calc(1080 / 1920 * 100%);
      }

      ${Inner} {
        height: 100%;
      }
      & ~ :nth-child(8){
        /* height:100% !important; */
        ${ImageLink},
        ${Image}{
          @media (min-width: 1280px) {
            /* width: 100%; */
            height: 517px !important;
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    .-layout-homepage-1 &:nth-child(4),
    .-layout-homepage-2 &:nth-child(1),
    .-layout-homepage-2 &:nth-child(2),
    .-layout-homepage-3 &:nth-child(1),
    .-layout-homepage &:nth-child(1) {
      height: calc(
        (100vw - ${p => p.theme.sidePadding.phone}px * 2) * 0.746268657
      );

      ${Inner},
      ${ImageLink},
      ${Image} {
        display: block;
        width: 100% !important;
        height: 100% !important;
      }

      ${Text} {
        margin-left: 0;
        left: 20px;
      }
    }

    .-layout-homepage-3 &:nth-child(2), .-layout-homepage &:nth-child(2) {
      &::before,
      &::after {
        display: none;
      }

      ${Text} {
        position: static;
        color: inherit;
      }

      ${Meta} {
        > :nth-child(n + 2) {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .-layout-slideshow & {
      min-width: 300px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      ${Inner} {
        flex-direction: column-reverse;
      }

      ${Image},
      ${ImagePlaceholder} {
        width: 300px !important;
        height: 250px !important;
      }

      ${Text} {
        display: block;
        margin-top: 15px;
        margin-left: 0;
      }

      ${Title} {
        margin-bottom: 12px;
      }

      ${MetaAuthor},
      ${MetaDate} {
        display: inline-block;
      }

      ${Description} {
        display: block;
      }
      
      &.-size-small{
        @media (max-width: ${breakpoints.tablet - 1}px) {
          min-width: 200px;
          ${Image},
          ${ImagePlaceholder} {
            width: 200px !important;
            height: 165px !important;
          }
        }
      }
    }
  }

  @media (max-width: 370px) {
    .-layout-slideshow & {
      min-width: 245px;

      ${Image},
      ${ImagePlaceholder} {
        width: 245px !important;
        height: 204px !important;
      }
    }
  }

  ${media.tablet} {
    margin-bottom: 0;

    &.-text-under-img {
      ${Inner} {
        flex-direction: column-reverse;
      }

      ${Text} {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    &.-text-on-bg {
      ${Text} {
        bottom: 20px;
      }

      &.-video {
        ${Text} {
          bottom: 61px;
        }
      }
    }

    &.-text-on-bg.-video {
      ${Text} {
        bottom: 71px;
      }
    }

    .-layout-homepage-1 & {

      &:nth-child(-n+3) {
        align-self: end;

        ${Text} {
          visibility: hidden;
        }
      }

      &:nth-child(4),
      &:nth-child(8) {
        &,
        ${ImageLink},
        ${Image} {
          height: 310px !important;
        }
      }

      &:nth-child(1) {
        grid-column: 1/4;
      }

      &:nth-child(2) {
        grid-column: 4/7;
      }

      &:nth-child(3) {
        grid-column: 7/9;
      }

      &:nth-child(4) {
        grid-row: 2/4;
        grid-column: 1/7;
      }

      &:nth-child(5) {
        grid-column: 1/3;
      }

      &:nth-child(6) {
        grid-column: 3/5;
      }

      &:nth-child(7) {
        grid-column: 5/7;
      }

      &:nth-child(8) {
        grid-row: 3/5;
        grid-column: 7/9;
      }
    }

    .-layout-homepage-2 & {
      &:nth-child(1),
      &:nth-child(2) {
        ${ImageLink},
        ${Image} {
          height: 307px !important;
        }
      }

      &:nth-child(1),
      &:nth-child(3) {
        grid-column: 1/4;
      }

      &:nth-child(2),
      &:nth-child(4) {
        grid-column: 4/7;
      }

      &:nth-child(5) {
        grid-column: 7/9;
      }
    }

    .-layout-homepage-3 &,  .-layout-homepage &  {
      &:nth-child(1) {
        grid-column: 1/9;

        ${ImageLink},
        ${Image} {
          height: 347px !important;
        }

        ${StyledVideoPlayer} {
          position: relative;
        }

        &.-video {
          height: calc((100vw - ${p =>
            p.theme.sidePadding.tablet}px * 2) * .5625);
        }
      }

      &:nth-child(2) {
        grid-column: 4/9;
      }

      &:nth-child(3) {
        grid-column: 1/3;
      }

      &:nth-child(4) {
        grid-column: 3/6;
      }

      &:nth-child(5) {
        grid-column: 6/9;
      }
    }

    .-layout-slideshow & {
      width: 300px;
      min-width: 300px;
      margin-right: 10px;
    }

    .-layout-category & {
      margin-bottom: 35px;

      /* &:nth-child(1) {
        grid-column: 1/3;
      } */
    }
  }

  ${media.desktop} {
    &.-text-on-bg {
      ${Text} {
        bottom: 10px;
      }

      &.-video {
        ${Text} {
          bottom: 36px;
        }
      }
    }

    .-layout-homepage-1 & {

      &:nth-child(4),
      &:nth-child(8) {
        &,
        ${ImageLink},
        ${Image} {
          height: 433px !important;
        }
      }
    }

    .-layout-homepage-2 & {
      &:nth-child(1),
      &:nth-child(2) {
        ${ImageLink},
        ${Image} {
          height: 473px !important;
        }
      }
    }

    .-layout-homepage-3 &,  .-layout-homepage & {
      &:nth-child(1) {

        ${ImageLink},
        ${Image} {
          height: 473px !important;
        }

        &.-video {
          height: calc((100vw - ${p =>
            p.theme.sidePadding.desktop}px * 2) * .5625);
        }
      }
    }
  }

  ${media.desktopMedium} {

    .-layout-homepage-1 & {
      &:nth-child(4),
      &:nth-child(8) {
        &,
        ${ImageLink},
        ${Image} {
          height: 600px !important;
        }
      }
    }

    .-layout-homepage-2 & {
      &:nth-child(1),
      &:nth-child(2) {
        ${ImageLink},
        ${Image} {
          height: 600px !important;
        }
      }
    }

    .-layout-homepage-3 & ,  .-layout-homepage &{
      &:nth-child(1) {

        ${ImageLink},
        ${Image} {
          height: 600px !important;
        }
      }

      &:nth-child(2) {
        grid-column: 3/9;
      }
    }

    .-layout-slideshow & {
      width: calc((100% - 70px) / 8 * 2 + 10px);
      min-width: calc((100% - 70px) / 8 * 2 + 10px);
    }
  }

  @media (min-width: 1280px) {
    .-layout-homepage-3 &:nth-child(1), .-layout-homepage &:nth-child(1) {
      &.-video {
        height: 691.875px !important;
      }
    }
  }
`
